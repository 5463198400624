.counter_up {
    background-image: url(../../../Assets/Images/counter_bg.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 50px 0;
}

.counter_item b {
    font-size: 36px;
    display: block;
    text-align: center;
    color: #fff;
    line-height: 1;
    margin-bottom: 10px;
}

.counter_item p {
    text-align: center;
    text-transform: capitalize;
    color: var(--white);
}