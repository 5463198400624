/* 
-----------------------------------------------------
Colors Variables
-----------------------------------------------------
*/
:root {
    --myFonts: 'Nunito',
        sans-serif;
    ;
    --primary: #525fe1;
    --secondary: #231f40;
    --white: #ffffff;
    --gray: #f5f5f5;
    --orange: #f86f03;
    --yellow: #ffa41b;
}

/* 
-----------------------------------------------------
Colors Variables
-----------------------------------------------------
*/

/* 
-----------------------------------------------------
Default CSS
-----------------------------------------------------
*/
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}

body {
    font-family: var(--myFonts);
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--myFonts);
    margin-bottom: 0;
}

p {
    font-family: var(--myFonts);
    margin-bottom: 0 !important;
    line-height: 30px;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

li {
    display: inline-block;
}

a,
a:hover {
    text-decoration: none !important;
}

button {
    border: none;
    outline: none;
}

button:focus {
    box-shadow: none;
}

.app {
    min-height: 80vh;
}

/* 
-----------------------------------------------------
Default CSS
-----------------------------------------------------
*/