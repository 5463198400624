
.about_section {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px 0;
}

.about_section .about_img {
    position: relative;
    z-index: 9;
}

.about_section .front_img {
    position: absolute;
    bottom: -55px;
    right: 30px;
}

.about_section .shape_img {
    position: absolute;
    top: -30px;
    z-index: -1;
    right: 20%;
}

.about_section .heading p {
    margin-top: 30px;
}

.about_section .heading {
    margin-bottom: 30px;
}

.about_section .about_tab {
    margin-bottom: 30px;
}

.about_section .about_tab .tab-pane {
    font-size: 14px;
}

.about_section .about_tab .nav-tabs .nav-link {
    background: #f5f6ff;
    border: 1px solid #f5f6ff;
    margin-bottom: 0;
}

.about_section .about_tab .nav-tabs .nav-link span {
    margin-right: 10px;
}

.about_section .about_tab .nav-tabs {
    border-bottom: 1px solid #525fe1;
    margin-bottom: 15px;
}

.about_section .about_tab .nav-tabs .nav-link.active {
    color: #ffff;
    background-color: #525fe1;
    border-color: #525fe1 #525fe1 #525fe1;
}


.about_section .custom_btn {
    background: var(--primary);
    padding: 20px 30px;
    display: inline-flex;
    color: var(--white) !important;
    text-transform: capitalize;
    border-radius: 4px;
    line-height: 1;
    gap: 10px;
    transition: all .3s ease;
}

.about_section .custom_btn>svg {
    transition: all .3s ease;
}

.about_section .custom_btn:hover>svg {
    transform: translateX(10px);
    transition: all .3s ease;
}

.about_section .heading span {
    color: var(--primary);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 7px;
}

.about_section .heading h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}

.about_section .heading p {
    margin-top: 30px;
}

.about_section .heading {
    margin-bottom: 70px;
}