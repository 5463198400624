footer {
    background: var(--secondary);
    padding: 100px 0 0;
}

footer .footer_wiget img {
    max-width: 180px;
}

footer .footer_wiget p {
    margin-top: 30px;
    color: var(--gray);
}

footer .social_link {
    display: flex;
    column-gap: 10px;
    margin-top: 30px;
}

footer .social_link li a {
    width: 40px;
    height: 40px;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    font-size: 18px;
    transition: all .3s ease;
    border-radius: 4px;
}

footer .social_link li:hover a {
    background: var(--primary);
    color: var(--white);
}

footer .footer_title {
    color: var(--white);
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 30px;
}

footer .links {
    display: grid;
    grid-row-gap: 15px;
}

footer .links li a {
    color: var(--white);
    text-transform: capitalize;
    font-size: 15px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

footer .links li a span {
    text-transform: uppercase;
}

footer .links li a i,
footer .links li a p {
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

footer .links li a:hover i {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
}

footer .links li a:hover p {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
}

footer .newsletter_arae p {
    color: var(--white);
    margin-bottom: 30px !important;
}

footer .footer_content .form-group {
    margin-bottom: 20px;
}

footer .newsletter_arae .form-control {
    background: transparent;
    height: 56px;
    border: 2px solid #ffffff73;
    color: var(--white);
    box-shadow: none;
    transition: 0.5s;

}

footer .newsletter_arae .form-control::placeholder {
    color: var(--white);
}

footer .newsletter_arae .form-control:focus,
footer .newsletter_arae .form-control:active {
    border: 2px solid var(--white);
    transition: 0.5s;
}

footer ul.fblog_area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

footer ul.fblog_area li {
    border-radius: 4px;
    overflow: hidden;
}

footer ul.fblog_area li img {
    transition: 0.5s;
}

footer ul.fblog_area li:hover img {
    transform: scale(1.2);
    transition: 0.5s;
}

footer span.copy_right {
    display: block;
    text-align: center;
    color: #808080;
    font-size: 14px;
}

footer .copy_right_bar {
    background: #ffffff2e;
    padding: 15px;
    margin-top: 30px;
}

footer .custom_btn {
    background: var(--primary);
    padding: 20px 30px;
    display: inline-flex;
    color: var(--white) !important;
    text-transform: capitalize;
    border-radius: 4px;
    line-height: 1;
    gap: 10px;
    transition: all .3s ease;
}

footer .custom_btn>svg {
    transition: all .3s ease;
}

footer .custom_btn:hover>svg {
    transform: translateX(10px);
    transition: all .3s ease;
}