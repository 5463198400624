/* 
-----------------------------------------------------
Gallery CSS
-----------------------------------------------------
*/

.gallery {
    padding: 120px 0;
}

.gallery_menu .filterbuttongroup {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 70px;
}

.gallery_menu .filterbuttongroup div {
    background: var(--gray);
    padding: 10px 20px;
}

.gallery_menu .filterbuttongroup div {
    background: var(--gray);
    padding: 12px 20px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 4px;
    color: var(--secondary);
    line-height: 1;
    cursor: pointer;
    transition: all .3s ease;
}

.gallery_menu .filterbuttongroup div:hover {
    background: var(--primary);
    color: var(--white);
}

.gallery_menu .filterbuttongroup div.active {
    background: var(--primary);
    color: var(--white);
}

.gallery_items {

    margin-bottom: 20px;
}

.gallery_img {
    position: relative;
    overflow: hidden;
}

.gallery_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.gallery_overley {
    background: linear-gradient(to right, #f77201, #f9aa05);
    position: absolute;
    top: 320px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 30px;
    color: var(--white);
    transition: all .4s ease;
}

.gallery_items:hover .gallery_overley {
    top: 0;
}

.gallery_content {
    border: 1px dashed #ffffff7d;
    padding: 20px 15px;
}

.gallery_content h4 {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    border-bottom: 1px dashed #cccccc7a;
    padding-bottom: 20px;
}

.gallery_content p {
    color: #fff;
    padding-bottom: 20px;
    border-bottom: 1px dashed #cccccc7a;
    margin-bottom: 30px;
}

.gallery_content ul {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.gallery_content ul li a {
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--secondary);
    transition: all .3s ease;
    border-radius: 4px;
}

.gallery_content ul li a:hover {
    background: var(--primary);
    color: var(--white);
}

.gallery .center_btn {
    margin: auto;
    display: table;
    margin-top: 130px;
}

.gallery .custom_btn {
    background: var(--primary);
    padding: 20px 30px;
    display: inline-flex;
    color: var(--white) !important;
    text-transform: capitalize;
    border-radius: 4px;
    line-height: 1;
    gap: 10px;
    transition: all .3s ease;
}

.gallery .custom_btn>svg {
    transition: all .3s ease;
}

.gallery .custom_btn:hover>svg {
    transform: translateX(10px);
    transition: all .3s ease;
}

.gallery .heading span {
    color: var(--primary);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 7px;
}

.gallery .heading h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}

.gallery .heading p {
    margin-top: 30px;
}

.gallery .heading {
    margin-bottom: 70px;
}