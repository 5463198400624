.about.style-4 .col-lg-5 ul {
    padding: 0;
}

.about.style-4 .content {
    position: relative;
    padding: 60px 0;
    margin: 40px 0;
}

.about.style-4 .content.frs-content .lines {
    position: absolute;
    left: 0;
    top: -80px;
    width: 40%;
    max-width: unset;
    max-height: unset;
}

.about.style-4 .content.frs-content .bubble {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    max-width: unset;
    max-height: unset;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both;
}

.rotate-center {
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.img.mb-30.mb-lg-0 {
    position: relative;
    z-index: 9;
}