.about_testimonial {
    background: url(../../../Assets/Images/feature-bg.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px 0;
}

.testimonial {
    padding: 120px 0;
}

.testimonial .testimonial_slider .owl-stage-outer {
    padding-top: 20px;
}

.testimonial .testimonial_items blockquote {
    width: 53%;
    margin: auto;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
    position: relative;
}

.testimonial .testimonial_items blockquote::before {
    content: url("../../../Assets/Images/icons8-quote-left.png");
    font-family: 'Fontawesome';
    font-size: 30px;
    position: absolute;
    top: -22px;
    left: 0px;
    color: var(--primary);
}

.testimonial .testimonial_items blockquote::after {
    content: url("../../../Assets/Images/icons8-quote.png");
    font-family: 'Fontawesome';
    font-size: 30px;
    position: absolute;
    bottom: -10px;
    right: 0px;
    color: var(--primary);
}


.testimonial .cl_img {
    width: 80px;
    margin: auto;
    margin-bottom: 20px;
}

.testimonial .cl_info h4 {
    font-size: 20px;
    color: var(--primary);
    font-weight: 700;
    margin-bottom: 5px;
}

.testimonial .cl_info span {
    color: #787878;
}

.testimonial .testimonial_slider .owl-dots {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.testimonial .testimonial_slider .owl-dots .owl-dot {
    background: #ddd;
    width: 10px;
    height: 10px;
    border-radius: 30px;
    position: relative;
}

.testimonial .testimonial_slider .owl-dots .owl-dot.active {
    background: linear-gradient(to right, #f77201, #f9aa05);
}

.testimonial .heading span {
    color: var(--primary);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 7px;
}

.testimonial .heading h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}

.testimonial .heading p {
    margin-top: 30px;
}

.testimonial .heading {
    margin-bottom: 70px;
}