.faq_sec .heading span {
    color: var(--primary);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 7px;
}

.faq_sec .heading h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}

.faq_sec .heading p {
    margin-top: 30px;
}

.faq_sec .heading {
    margin-bottom: 70px;
}

section.faq_sec {
    padding: 80px 0;
}

.accordion-button:not(.collapsed)::after {
    filter: brightness(0) invert(1);
}