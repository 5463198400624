.banner {
    background-image: url(../../../Assets/Images/bg.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 60px 0px 0px;
}

.banner .banner_container h5 {
    font-size: 20px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    padding-left: 50px;
}

.banner .banner_container h5::before {
    content: '';
    width: 40px;
    height: 2px;
    background: var(--primary);
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.banner .banner_container h1 {
    font-size: 52px;
    font-weight: 700;
    color: var(--secondary);
    line-height: 65px;
    margin-bottom: 25px;
}

.banner .banner_container p {
    font-size: 18px;
    margin-bottom: 30px;
}

.banner .custom_btn {
    background: var(--primary);
    padding: 20px 30px;
    display: inline-flex;
    color: var(--white) !important;
    text-transform: capitalize;
    border-radius: 4px;
    line-height: 1;
    gap: 10px;
    transition: all .3s ease;
}

.banner .custom_btn>svg {
    transition: all .3s ease;
}

.banner .custom_btn:hover>svg {
    transform: translateX(10px);
    transition: all .3s ease;
}

.banner .banner_img {
    position: relative;
    z-index: 5;
}

.banner .banner_img1 {
    display: table;
    margin-left: auto;
}

.banner .img_bg {
    position: absolute;
    left: 10%;
    z-index: -1;
    max-width: 500px;
    width: calc(100% - 18px);
    top: 50%;
    transform: translateY(-50%);
}

.banner .waves {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 99999;
    bottom: -5px;
}

.banner .wave {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../Assets/Images/wave.png);
    background-size: 1000px 100px;
}

.banner .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
}

.banner .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
}

.banner .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
}

.banner .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}